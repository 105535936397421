import { iAddress, iAddressPage } from "src/interfaces/address";
import Address from "src/models/Address";
import httpClientRegister from "src/http/register";

class addressConsumer {
  static get(idCompany: string, page: iAddressPage, addressType?: string) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());
    search.append("EmpresaId", idCompany);

    if (addressType && addressType !== "select")
      search.append("EnderecoTipo", addressType);

    return httpClientRegister.get(`/Endereco?${search.toString()}`);
  }

  static async created(address: iAddress) {
    const response = await httpClientRegister.post(
      `/Endereco`,
      Address.adapterToBody(address)
    );

    return response;
  }

  static updated(address: iAddress) {
    return httpClientRegister.put(`/Endereco`, Address.adapterToBody(address));
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/Endereco?id=${id}`);
  }
}

export default addressConsumer;
