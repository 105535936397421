/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { iInvoicing, iInvoicingContextProps } from "src/interfaces/invoicing";
import { iPage } from "src/interfaces/layout";
import Invoicing from "src/models/Invoicing";
import invoicesConsumer from "src/services/invoices";
import invoicingConsumer from "src/services/invoicing";
import invoicingStatusConsumer from "src/services/invoicingStatus";
import PaymentConditionConsumer from "src/services/paymentConditions";
import { addDays, addHours, format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

const InvoicingContext = createContext<iInvoicingContextProps>(
  {} as iInvoicingContextProps
);

export function InvoicingProvider({ children }: { children: any }) {
  const router = useNavigate();
  const { lang } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [invoicings, setInvoicings] = useState<Array<iInvoicing>>([]);
  const [invoicingSelect, setInvoicingSelect] = useState<iInvoicing | null>(
    null
  );
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [client, setClient] = useState<string>("");
  const [status, setStatus] = useState<string>("select");
  const [statusInvoicing, setStatusInvoicing] = useState<any[]>([]);
  const [paymentCondition, setPaymentCondition] = useState<any>("select");
  const [paymentConditions, setPaymentConditions] = useState<any[]>([]);
  const [nf, setNf] = useState<string>("");
  const [observations, setObservations] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [invoicingDate, setInvoicingDate] = useState<string>("");
  const [paymentDate, setPaymentDate] = useState<string>("");
  const [paymentValue, setPaymentValue] = useState<string>("R$ 0,00");
  const [invoicingValue, setInvoicingValue] = useState<number>(0);
  const [statusAvaliable, setStatusAvaliable] = useState<any[]>([]);
  const [adamentoStatus, setAdamentoStatus] = useState<any[]>([]);
  const [supplyer, setSupplyer] = useState<any>(null);
  const [supplyers, setSupplyers] = useState<any[]>([]);
  const [invoicesSupplyer, setInvoicesSupplyer] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState<any>({ number: 1, order: true });
  const [invoicesSupplyerSelected, setInvoicesSupplyerSelected] = useState<
    any[]
  >([]);

  const [clientSearch, setClientSearch] = useState<string>("");
  const [nfSearch, setNfSearch] = useState<string>("");
  const [dateFromSearch, setDateFromSearch] = useState<string>("");
  const [dateToSearch, setDateToSearch] = useState<string>("");
  const [statusSearch, setStatusSearch] = useState<string>("select");
  const [dataInicialPrevPgto, setDataInicialPrevPgto] = useState<string>("");
  const [dataFinalPrevPgto, setDataFinalPrevPgto] = useState<string>("");

  const [disableSupplier, setDisableSupplier] = useState<boolean>(true);

  const cleanFilters = () => {
    setClientSearch("");
    setNfSearch("");
    setDateFromSearch("");
    setDateToSearch("");
    setStatusSearch("select");
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getInvoicings = async () => {
    setLoading(true);
    try {
      const response = await invoicingConsumer.get(
        page,
        clientSearch.trim(),
        nfSearch.trim(),
        dateFromSearch,
        dateToSearch,
        statusSearch,
        orderBy
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      const data = response.data.items.map((item: any) =>
        Invoicing.adapterToClass(item)
      );

      setInvoicings(data);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar os faturamentos!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getInvoicingsStatus = async () => {
    try {
      const response = await invoicingStatusConsumer.get();

      if (response.status !== 200) throw response;

      setStatusAvaliable(response.data?.items);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar os status!"
      );
    }
  };

  const getSuppliersWithoutInvocies = async () => {
    try {
      if (!dataInicialPrevPgto || !dataFinalPrevPgto) {
        return toast.info(
          "Favor preencha os campos de data inicio e data fim!"
        );
      }

      setLoading(true);

      const pageData = {
        page: 0,
        rowsPerPage: 100,
        total: 0,
      };

      const response = await invoicesConsumer.getInvoiceWithSupplier(
        pageData,
        dateFrom,
        dateTo,
        dataInicialPrevPgto,
        dataFinalPrevPgto,
        "",
        true,
        "NaoFaturados"
      );

      if (!response.data.data || response.status !== 200) throw response;

      setSupplyers(response.data.data);

      setDisableSupplier(!disableSupplier);
    } catch (e: any) {
      toast.warning(
        e?.data?.message ??
          "Desculpe, tivemos um problema em buscar os dados dos fornecedores!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getPaymentConditionsAdministrator = async () => {
    try {
      setLoading(true);

      const response =
        await PaymentConditionConsumer.getPaymentConditionAdministrator({
          page: 0,
          rowsPerPage: 100,
          total: 0,
          change: false,
        });

      setPaymentConditions(response.data.items);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Erro ao buscar as condições de pagamento!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (data: iInvoicing | null) => {
    setInvoicingSelect(data);

    if (data) setStatus(data?.statusId ?? "select");

    setPaymentValue(
      parseFloat(data?.paymentValue ?? "0.0").toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "0,00"
    );
    setInvoicingValue(data?.invoiceValue ?? 0);
    setPaymentCondition(data?.paymentConditionId ?? "select");

    setSupplyer({
      idEmissor: data?.id,
      nomeFantasiaEmissor: data?.companyName,
    });

    setInvoicesSupplyer(data?.notasFiscais ?? []);

    const invoicesSelect = data?.notasFiscais?.filter((e) => {
      if (e?.faturamentoId) return e;
      return null;
    });

    setInvoicesSupplyerSelected(invoicesSelect ?? []);

    if (data?.periodoFatInicial)
      setDataInicialPrevPgto(
        format(
          addHours(new Date(data.periodoFatInicial.substring(0, 10)), 3),
          "yyyy-MM-dd"
        )
      );
    if (data?.periodoFatFinal)
      setDataFinalPrevPgto(
        format(
          addHours(new Date(data.periodoFatFinal.substring(0, 10)), 3),
          "yyyy-MM-dd"
        )
      );

    if (data?.registerDate) {
      setInvoicingDate(
        format(new Date(data?.registerDate.substring(0, 10)), "yyyy-MM-dd")
      );
    }

    if (data?.paymentDatePrev)
      setPaymentDate(
        format(new Date(data?.paymentDatePrev.substring(0, 10)), "yyyy-MM-dd")
      );
  };

  const handleDelete = async (data: iInvoicing) => {
    try {
      setLoading(true);

      const response = await invoicingConsumer.deleted(data?.id);

      if (response.status !== 200) throw response;

      setPage({ ...page, page: 0 });

      const newArray = invoicings.filter((e: iInvoicing) => e.id !== data.id);
      setInvoicings(newArray);

      toast.success("Fatura deletada com sucesso!");
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao deletar a fatura!");
    } finally {
      setLoading(false);
    }
  };

  const handleNewSalve = async () => {
    if (!paymentCondition || paymentCondition === "select") {
      return toast.info("Favor selecione uma opção de pagamento!");
    }

    if (!invoicingDate) {
      return toast.info("Favor informar a data de faturamento!");
    }

    try {
      setLoading(true);
      const countActualDay = 1;
      const payment = paymentConditions.find((e) => e.id === paymentCondition);
      const daysSum = countActualDay + payment?.numeroDias;

      const paymentPrevisionDate = format(
        addDays(new Date(invoicingDate), daysSum),
        "yyyy-MM-dd"
      );

      const body = new Invoicing(
        "",
        invoicingDate,
        invoicingValue,
        paymentPrevisionDate,
        "",
        "",
        "",
        "",
        "",
        paymentDate,
        paymentValue
          ?.trim()
          ?.replace("R$", "")
          ?.replaceAll(".", "")
          ?.replaceAll(",", ".")
          .trim(),
        supplyer?.idEmissor,
        "",
        status,
        "",
        dataInicialPrevPgto,
        dataFinalPrevPgto,
        invoicesSupplyerSelected.map((item: any) => {
          return {
            notaFiscalId: item.id,
            numero: item?.numero,
          };
        }),
        paymentCondition,
        paymentPrevisionDate
      );

      const response = await invoicingConsumer.created(body);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      ) {
        throw response;
      }

      toast.success("Faturamento cadastrado com sucesso!");
      router(`/${lang}/invoicing`);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao cadastrar o faturamento!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const countActualDay = 1;
      const payment = paymentConditions.find((e) => e.id === paymentCondition);
      const daysSum = countActualDay + payment?.numeroDias;

      const paymentPrevisionDate = format(
        addDays(new Date(invoicingDate), daysSum),
        "yyyy-MM-dd"
      );

      const body = new Invoicing(
        invoicingSelect?.id ?? "",
        invoicingDate,
        invoicingValue,
        paymentPrevisionDate,
        "",
        "",
        "",
        "",
        "",
        paymentDate,
        paymentValue
          ?.trim()
          ?.replace("R$", "")
          ?.replaceAll(".", "")
          ?.replaceAll(",", ".")
          .trim(),
        supplyer?.idEmissor,
        "",
        status,
        "",
        dataInicialPrevPgto,
        dataFinalPrevPgto,
        invoicesSupplyerSelected.map((item: any) => {
          return {
            notaFiscalId: item.id,
            numero: item?.numero,
          };
        }),
        paymentCondition,
        paymentPrevisionDate
      );

      const response = await invoicingConsumer.updated(body);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      ) {
        throw response;
      }

      toast.success("Faturamento alterado com sucesso!");
    } catch {
      toast.error("Erro ao alterar o faturamento!");
    } finally {
      setLoading(false);
    }
  };

  const getStatusInvoicing = async () => {
    try {
      setLoading(true);

      const response = await invoicingConsumer.getStatus({
        page: 0,
        rowsPerPage: 100,
        total: 0,
      });

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      ) {
        throw response;
      }

      setStatusInvoicing(response.data.items);
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao buscar os status!");
    } finally {
      setLoading(false);
    }
  };

  const getStatusAndamento = async (id: string) => {
    try {
      setLoading(true);

      const response = await invoicingConsumer.getVinculateWithStatus(
        {
          page: 0,
          rowsPerPage: 100,
          total: 0,
        },
        id
      );

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      ) {
        throw response;
      }

      setAdamentoStatus(response.data.items);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao buscar os andamentos de status!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      setPage,
      getInvoicings,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      invoicings,
      setInvoicings,
      client,
      setClient,
      status,
      setStatus,
      nf,
      setNf,
      dateFrom,
      setDateFrom,
      dateTo,
      setDateTo,
      statusAvaliable,
      setStatusAvaliable,
      handleSelect,
      invoicingSelect,
      setInvoicingSelect,
      handleDelete,
      supplyer,
      setSupplyer,
      invoicingDate,
      setInvoicingDate,
      invoicingValue,
      setInvoicingValue,
      paymentCondition,
      setPaymentCondition,
      paymentDate,
      setPaymentDate,
      paymentValue,
      setPaymentValue,
      observations,
      setObservations,
      handleNewSalve,
      handleUpdate,
      disableSupplier,
      setDisableSupplier,
      getSuppliersWithoutInvocies,
      paymentConditions,
      setPaymentConditions,
      getPaymentConditionsAdministrator,
      supplyers,
      setSupplyers,
      invoicesSupplyer,
      setInvoicesSupplyer,
      invoicesSupplyerSelected,
      setInvoicesSupplyerSelected,
      getStatusInvoicing,
      statusInvoicing,
      setStatusInvoicing,
      adamentoStatus,
      setAdamentoStatus,
      getStatusAndamento,
      orderBy,
      setOrderBy,
      cleanFilters,
      clientSearch,
      setClientSearch,
      nfSearch,
      setNfSearch,
      dateFromSearch,
      setDateFromSearch,
      dateToSearch,
      setDateToSearch,
      statusSearch,
      setStatusSearch,
      dataInicialPrevPgto,
      setDataInicialPrevPgto,
      dataFinalPrevPgto,
      setDataFinalPrevPgto,
      getInvoicingsStatus,
    };
  }, [
    page,
    loading,
    invoicings,
    client,
    status,
    nf,
    dateFrom,
    dateTo,
    statusAvaliable,
    invoicingSelect,
    supplyer,
    invoicingDate,
    invoicingValue,
    paymentCondition,
    paymentDate,
    paymentValue,
    observations,
    disableSupplier,
    paymentConditions,
    supplyers,
    invoicesSupplyer,
    invoicesSupplyerSelected,
    statusInvoicing,
    adamentoStatus,
    orderBy,
    clientSearch,
    nfSearch,
    dateFromSearch,
    dateToSearch,
    statusSearch,
    dataInicialPrevPgto,
    dataFinalPrevPgto,
  ]);

  return (
    <InvoicingContext.Provider value={contextValue}>
      {children}
    </InvoicingContext.Provider>
  );
}

export default InvoicingContext;
