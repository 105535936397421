/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, Typography, Grid, TextField } from "@mui/material";
import useLogin from "src/hooks/useLogin";
import LOGO from "src/assets/images/Logo.svg";
import { useLocation, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { palette } from "src/theme";
import LoginConsumer from "src/services/login";
import Swal from "sweetalert2";
import CircularLoading from "src/components/Loading";

const LoginExternal = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const { code, handleChange } = useLogin();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token") ?? "";

  useEffect(() => {
    getCode(token);
  }, []);

  const getCode = async (token: string) => {
    setLoading(true);

    try {
      await LoginConsumer.sendUserIdExternal(token);
    } catch (e: any) {
      Swal.fire({
        title: "Ops...",
        text: `${e?.response?.data ?? "Desculpe, ocorreu um erro!"}`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      }).then((_) => {});
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const loginExternal = true;

    handleChange(index, value, loginExternal, token);
    if (value.length === 1 && index < code.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", padding: 2, background: palette.primary.main }}
    >
      <Card
        variant="outlined"
        style={{ backgroundColor: "#ffffff", padding: "5%" }}
      >
        <CardContent>
          {loading ? (
            <CircularLoading />
          ) : (
            <>
              <Grid
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="subtitle1" gutterBottom textAlign="center">
                  {translations[param]["message_login_one"]}
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  gutterBottom
                  color="primary"
                  textAlign="center"
                >
                  {translations[param]["enter_the_validation_code"]} enviado
                  para seu e-mail!
                </Typography>
              </Grid>
              <Grid container spacing={1} justifyContent="center">
                {code.map((digit: any, index: number) => (
                  <Grid item key={index}>
                    <TextField
                      variant="outlined"
                      type="text"
                      value={digit}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      inputProps={{
                        maxLength: 1,
                        style: {
                          textAlign: "center",
                          width: "2rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px", marginTop: "10%" }}
          >
            <img alt="logo" src={LOGO} />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default LoginExternal;
