/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { maskCnpj } from "src/functions/text";
import { iPage } from "src/interfaces/layout";
import ParticipatingCompaniesConsumer from "src/services/participatingCompanies";
import ShoppingProcessDemandClientConsumer from "src/services/shoppingProcessDemandClient";
import SolicitacaoCompraConsumer from "src/services/solicitacaoCompra";
import Swal from "sweetalert2";
import { utils, read, writeFile } from "xlsx";

export interface FormValues {
  descricaoProcesso: string;
  numeroProcesso: string;
  cnpj: string;
  empresa: string;
  compradorResponsavel: string;
  email: string;
  rede: string;
  telefone: string;
  cartaConvite: string;
  codigo: string;
  descricao: string;
  codigoCliente: string;
  descricaoCliente: string;
  quantidade: string;
  valorUnitario: string;
  valorAlvo: string;
  possuiInteresse: boolean;
  produtoEmpresaId?: string;
  produtoId?: string;
  processoCompraDemandaItemId?: string;
}

const DemandCollectionFormContext = createContext<any>({} as any);

export function DemandCollectionFormProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [open, setOpen] = useState(Array(3).fill(false));
  const [type, setType] = useState(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [demand, setDemand] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);
  const [daysOfWeek, setDaysOfWeek] = useState<any[]>([]);
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const [itemSections, setItemSections] = useState<FormValues[]>([]);
  const [indicacaoItensSections, setIndicacaoItensSections] = useState<
    FormValues[]
  >([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery("(min-width:1400px)");

  const handleInputChange = (event: any, index: number, section: string) => {
    let { name, value, checked } = event.target;

    // Atualiza todos os campos de uma vez
    if (name === "multiChange") {
      if (section === "item") {
        setItemSections((prev) =>
          prev.map((item, idx) =>
            idx === index ? { ...item, ...value } : item
          )
        );
      } else {
        setIndicacaoItensSections((prev) =>
          prev.map((item, idx) =>
            idx === index ? { ...item, ...value } : item
          )
        );
      }
    } else {
      if (name === "possuiInteresse") {
        value = !checked;
      }

      const updateSections = (sections: FormValues[]) => {
        const updatedSections = [...sections];
        updatedSections[index] = { ...updatedSections[index], [name]: value };
        return updatedSections;
      };

      if (section === "item") {
        setItemSections(updateSections(itemSections));
      } else if (section === "indicacaoItens") {
        setIndicacaoItensSections(updateSections(indicacaoItensSections));
      }
    }
  };

  const handleAddSection = (section: string) => {
    if (section === "item") {
      setItemSections([...itemSections, {} as FormValues]);
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections([...indicacaoItensSections, {} as FormValues]);
    }
  };

  const handleRemoveSection = (index: number, section: string) => {
    if (section === "item") {
      setItemSections(itemSections.filter((_, i) => i !== index));
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections(
        indicacaoItensSections.filter((_, i) => i !== index)
      );
    }
  };

  const handleToggle = (index: any) => {
    setOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const send = async (e: any, demand: any, empresaId: string, type: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      const items = itemSections
        .map((e) => {
          const data: any = {
            valorAlvo: e?.possuiInteresse
              ? e?.valorAlvo
                  ?.toString()
                  ?.replace("R$", "")
                  ?.replaceAll(".", "")
                  ?.replace(",", ".")
                  ?.trim() ?? "0,00"
              : 0.0,
            valorReferencia: e?.possuiInteresse
              ? e?.valorUnitario
                  ?.toString()
                  ?.replace("R$", "")
                  ?.replaceAll(".", "")
                  ?.replace(",", ".")
                  ?.trim() ?? "0,00"
              : 0.0,
            empresaId: empresaId,
            processoCompraId: demand?.processoCompraId ?? "",
            solicitacaoCompraTipoId: type?.id ?? "",
            data: new Date(),
            quantidade: e?.possuiInteresse ? e?.quantidade : 0,
            possuiInteresse: e?.possuiInteresse,
            responsavel: demand?.nomeComprador ?? "",
            sugestao: false,
            codigoProduto: e?.codigoCliente ?? null,
            descricaoProduto: e?.descricaoCliente ?? null,
            produtoId: e?.produtoId ?? "",
            processoCompraDemandaItemId: e?.processoCompraDemandaItemId,
          };

          if (e?.produtoEmpresaId) {
            data["produtoEmpresaId"] = e?.produtoEmpresaId;
          }

          return data;
        })
        .filter((e: any) => e.possuiInteresse && e);

      const suggestions = indicacaoItensSections
        .map((e) => {
          const data: any = {
            valorAlvo: e?.possuiInteresse
              ? e?.valorAlvo
                  ?.toString()
                  ?.replace("R$", "")
                  ?.replaceAll(".", "")
                  ?.replace(",", ".")
                  ?.trim() ?? "0,00"
              : 0.0,
            valorReferencia: e?.possuiInteresse
              ? e?.valorUnitario
                  ?.toString()
                  ?.replace("R$", "")
                  ?.replaceAll(".", "")
                  ?.replace(",", ".")
                  ?.trim() ?? "0,00"
              : 0.0,
            empresaId: empresaId,
            processoCompraId: demand?.processoCompraId ?? "",
            solicitacaoCompraTipoId: type?.id ?? "",
            data: format(new Date(), "yyyy-MM-dd"),
            quantidade: e?.possuiInteresse ? e?.quantidade : 0,
            possuiInteresse: true,
            responsavel: demand?.nomeComprador ?? "",
            sugestao: true,
            codigoProduto: e?.codigoCliente ?? null,
            descricaoProduto: e?.descricaoCliente ?? null,
          };

          if (e?.produtoEmpresaId) {
            data["produtoEmpresaId"] = e?.produtoEmpresaId;
          }

          return data;
        })
        .filter((e: any) => e?.possuiInteresse && e);

      const response = await SolicitacaoCompraConsumer.created({
        solicitacaoCompra: [...items, ...suggestions],
      });

      if (response.status !== 200) throw response;

      Swal.fire(
        "Sucesso!",
        "Solicitação enviada, você será redirecionado para a página de login.",
        "success"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.replace("https://www.supply4med.com.br/");
        }
      });
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message ?? "Erro ao enviar os dados!");
    } finally {
      setLoading(false);
    }
  };

  const declinou = async (demand: any, empresaId: string) => {
    setLoading(true);

    console.log(demand);

    try {
      const body: any = {
        id: demand.processoCompraParticipanteId,
        processoComprasId: demand.processoCompraId,
        empresasId: empresaId,
        declinouParticipacao: true,
      };

      if (demand?.responsavelCompradorId) {
        body["responsavelCompradorId"] = demand?.responsavelCompradorId;
      }

      const response = await ParticipatingCompaniesConsumer.updated(body);

      if (response.status !== 200) throw response;

      Swal.fire(
        "Sucesso!",
        "Declinado com sucesso, você será redirecionado para a página de login.",
        "success"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.replace("https://www.supply4med.com.br/");
        }
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Desculpe, não foi possível finalizar a operação!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getType = async () => {
    setLoading(true);

    try {
      const response = await SolicitacaoCompraConsumer.getType(page);

      if (response.status !== 200) throw response;

      const currentType = response.data.items.find(
        (e: any) =>
          e.descricao.toUpperCase() ===
          "Solicitação de Demanda de Compra".toUpperCase()
      );

      setType(currentType);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Desculpe, não foi possível buscar os dados de tipo da solicitação!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    setLoading(true);

    reader.onload = (event: any) => {
      const data = event?.target.result;
      const workbook = read(data, { type: "binary" });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const sheetData = utils.sheet_to_json(worksheet, { header: 1 });

      const validData = sheetData.slice(2); // Remove as duas primeiras linhas

      processSpreadsheetData(validData);
    };

    reader.readAsBinaryString(file);
  };

  const processSpreadsheetData = async (sheetData: any) => {
    const rows = sheetData.slice(1);

    const updatedItems = demand.processoCompraDemandaItens.map((item: any) => {
      const correspondingRow = rows.find(
        (row: any) => row[0] === item.produtoCodigo
      );

      if (correspondingRow) {
        return {
          codigo: correspondingRow[0] || item.produtoCodigo,
          descricao: correspondingRow[1] || item.produtoDescricao,
          codigoCliente: correspondingRow[2] || "",
          descricaoCliente: correspondingRow[3] || "",
          quantidade: correspondingRow[4] || "",
          valorUnitario:
            correspondingRow[5] === "0" ||
            correspondingRow[5] === "0.0" ||
            correspondingRow[5] === "0,0" ||
            correspondingRow[5] === "0.00" ||
            correspondingRow[5] === "0,00" ||
            correspondingRow[5] === 0 ||
            correspondingRow[5] === 0.0
              ? ""
              : correspondingRow[5],
          valorAlvo:
            correspondingRow[6] === "0" ||
            correspondingRow[6] === "0.0" ||
            correspondingRow[6] === "0,0" ||
            correspondingRow[6] === "0.00" ||
            correspondingRow[6] === "0,00" ||
            correspondingRow[6] === 0 ||
            correspondingRow[6] === 0.0
              ? ""
              : correspondingRow[6],
          possuiInteresse: correspondingRow[7] === "SIM" ? true : false,
          produtoEmpresaId: null,
          produtoId: item.produtoId,
          processoCompraDemandaItemId: item.id,
        };
      }

      return item;
    });

    setItemSections(updatedItems);

    setLoading(false);
  };

  const gererateCSV = (demand: any) => {
    const header_ittens = {
      comlumn_1: "Cód",
      comlumn_2: "Descrição",
      comlumn_3: "Cód Cliente",
      comlumn_4: "Descrição Cliente",
      comlumn_5: "Quantidade",
      comlumn_6: "Valor Últ. Compra (R$)",
      comlumn_7: "Valor Alvo (R$)",
      comlumn_8: "Não Possui Interesse ?",
    };

    const instructions = [
      "Preencha os valores nos campos 'Valor Últ. Compra (R$)' e 'Valor Alvo (R$)' com valores numéricos válidos. exemplo: (Dez reais) 10,00, (Cem reais) 100,00, (Mil reais) 1.000,00, (valores com centavos, exemplo R$ 1.589,76) 1.589.76, favor informe apenas números e as separações das casas decimais!",
      "Selecione 'SIM' ou 'NÃO' na coluna 'Não Possui Interesse ?' para indicar o interesse.",
    ];

    // Convertendo os cabeçalhos para arrays e adicionando orientações
    const sheet1Data = [
      ...instructions.map((instr) => [instr]), // Instruções de preenchimento
      objectToArray(header_ittens), // Cabeçalhos
    ];

    // Preenchendo os dados do array demand.processoCompraDemandaItens
    demand.processoCompraDemandaItens.forEach((item: any) => {
      sheet1Data.push([
        item.produtoCodigo, // Coluna "Cód"
        item.produtoDescricao, // Coluna "Descrição"
        item.codigoCliente || "", // Coluna "Cód Cliente"
        item.descricaoCliente || "", // Coluna "Descrição Cliente"
        item.quantidade || "", // Coluna "Quantidade"
        item?.valorUltimaCompra || "", // Valor Última Compra com 2 casas decimais
        item?.valorAlvo || "", // Valor Alvo com 2 casas decimais
        item.possuiInteresse ? "NÃO" : "SIM", // Valor padrão "SIM" ou "NÃO"
      ]);
    });

    const ws1 = utils.aoa_to_sheet(sheet1Data);

    // Definindo a largura das colunas para ws1
    ws1["!cols"] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 15 },
      { wch: 30 },
      { wch: 12 },
      { wch: 20 },
      { wch: 15 },
      { wch: 25 },
    ];

    // Adicionando validação de dados (dropdown select) para a coluna "Não Possui Interesse ?"
    const range = `H${instructions.length + 2}:H${sheet1Data.length + 1}`;
    ws1["!dataValidations"] = [
      {
        sqref: range,
        type: "list",
        formula1: '"SIM,NÃO"',
        showDropDown: true,
      },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "Itens");

    writeFile(wb, "planilha_itens.xls");
  };

  const objectToArray = (obj: any) => Object.values(obj);

  const getShoppingProcessDemandClient = async (
    shoppingProccessId: string,
    companyId: string
  ) => {
    setIsError(false);
    setLoading(true);
    try {
      const response = await ShoppingProcessDemandClientConsumer.get(
        page,
        shoppingProccessId,
        companyId
      );

      if (response.status !== 200) throw response;

      const startItems = response.data?.processoCompraDemandaItens?.map(
        (item: any, _: number) => {
          return {
            produtoId: item.produtoId,
            codigo: item.produtoCodigo,
            descricao: item.produtoDescricao,
            possuiInteresse: true,
            processoCompraDemandaItemId: item.id,
          };
        }
      );

      setDemand({
        ...response.data,
        cnpj: maskCnpj(response.data.cnpj),
      });

      return startItems;
    } catch (e) {
      setIsError(true);
      toast.error("Ops... identificamos um erro ao buscar as demandas!");
    } finally {
      setLoading(false);
    }
  };

  const registerAddress = async () => {
    setLoading(true);

    try {
      if (!address) {
        // TODO: salvar os dados na tabela de - Endereço
      }

      // vincular o endereço na tabela - PCParticipantesEntrega
      console.log(address);

      // Salvar as horas de entrega na tabela - EnderecosHorariosEntrega
      console.log(daysOfWeek);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao salvar os endereços de entrega!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      isMobile,
      isLargeScreen,
      handleInputChange,
      handleToggle,
      open,
      handleAddSection,
      indicacaoItensSections,
      handleRemoveSection,
      send,
      itemSections,
      setItemSections,
      getType,
      type,
      setType,
      handleFileUpload,
      gererateCSV,
      getShoppingProcessDemandClient,
      isError,
      setIsError,
      demand,
      setDemand,
      declinou,
      address,
      setAddress,
      daysOfWeek,
      setDaysOfWeek,
      expandedAccordion,
      setExpandedAccordion,
      registerAddress,
    };
  }, [
    page,
    loading,
    isMobile,
    isLargeScreen,
    open,
    indicacaoItensSections,
    itemSections,
    indicacaoItensSections,
    type,
    isError,
    demand,
    address,
    daysOfWeek,
    expandedAccordion,
  ]);

  return (
    <DemandCollectionFormContext.Provider value={contextValue}>
      {children}
    </DemandCollectionFormContext.Provider>
  );
}

export default DemandCollectionFormContext;
